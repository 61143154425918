<template>
  <div style="height: inherit">
    <section>
      <div>
        <h1 class="mb-4">
          Todos los promocionales
        </h1>
      </div>
    </section>

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">

              <div
                class="search-results d-block d-lg-none filter-heading c-hand p-1"
                @click="mqShallShowLeftSidebar = true"
              >
                Filtrar búsqueda
              </div>
              <div class="search-results">
                {{ totalProducts }} Resultados encontrados
              </div>
            </div>
            <div class="view-options d-flex">

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              ref="search"
              v-model="filters.q"
              placeholder="Búsqueda de producto"
              class="search-product"
              @keyup.enter="filterAction"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Products -->
    <section :class="itemView">
      <div
        v-for="product in products.data"
        :key="product.id"
        class=""
      >
        <div class="item-img text-center img-overlay">
          <b-link
            class="d-block m-auto"
            :to="{ name: 'product-details', params: { slug: product.slug } }"
          >
            <span v-if="product.discount_price" class="float-discont">-{{ ((product.discount_price-product.price)/product.discount_price*100).toFixed(2) }}%</span>
            <b-img
              :alt="`${product.name}-${product.id}`"
              fluid
              loading="lazy"
              class="img-overlay-image"
              :src="product.thumbnails[0]"
            />
            <div class="overlay">
              <div class="text">
                Más información
              </div>
            </div>
          </b-link>
        </div>

        <!-- Product Details -->
        <div>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <div v-if="product.free_shipping_enabled">
                  <b-badge
                    v-if="product.qty_free_shipping === 1"
                    pill
                    variant="light-success"
                  >
                    Envío gratis
                  </b-badge>

                  <b-badge
                    v-else
                    variant="light-success"
                  ><!-- todo: aquí hay que ponerlo decolores segun el articulo-->
                    Envío gratis a partir de {{ product.qty_free_shipping }} paq.
                  </b-badge>

<!--                  <feather-icon
                    size="20"
                    icon="StarIcon"
                    class="mb-icon float-right"
                  />--><!-- todo: la estrella si le dan-->
                </div>
              </ul>
            </div>
            <div>
              <h6 class="item-name">
                <b-link
                  class="text-body"
                  :to="{ name: 'product-details', params: { slug: product.slug } }"
                >
                  {{ product.name }}
                </b-link>
              </h6>
            </div>
          </div>
          <h3 class="item-price">
            ${{ product.price }}
            <strike v-if="product.discount_price" class="small">${{ product.discount_price }}</strike>
          </h3>
        </div>

      </div>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @reset-filters="cleanFilters"
        @filter-action="filterAction"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BLink,
  BImg,
  BPagination,
  BBadge,
} from 'bootstrap-vue'

import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { onBeforeMount, watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BLink,
    BImg,
    BPagination,

    // SFC
    ShopLeftFilterSidebar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    category: {
      type: String,
      required: false,
    },
    searchActive: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    brand: {
      type: String,
      required: false,
    },
  },
  mounted() {
    if (this.brand) {
      setTimeout(() => {
        this.$set(this.filters, 'brands', this.brand)
        this.fetchShopProducts()
      }, 750)
    }
    if (this.searchActive) {
      this.$refs.search.focus()
    }
  },
  setup(props) {
    const { category, brand } = props
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination(category, brand)

    onBeforeMount(async () => {
      const avoidCategories = []
      const avoidBrands = ['Felices Fiestas']
      await store.dispatch('products/listCategories')
      const brands = await store.dispatch('products/listBrands')
      const brandsFetch = []
      brands.data.variants.forEach(brandOption => {
        brandsFetch.push({
          text: brandOption.value,
          value: brandOption.id,
        })
      })
      const categories = store.getters['products/listCategories'].filter(obj => !avoidCategories.includes(obj.text))
      filterOptions.categories = categories
      filterOptions.brands = store.getters['products/listBrands'].filter(obj => !avoidBrands.includes(obj.text))
      console.log(brandsFetch)
      filterOptions.brands = brandsFetch.filter(obj => !avoidBrands.includes(obj.text))
      filters.value = {
        q: '',
        priceRangeDefined: null,
        priceRange: [0, 17000],
        categories: category,
        brands: brand,
        ratings: null,
        page: 1,
        perPage: 15,
      }
    })

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        page: filters.value.page,
        search: filters.value.q,
        sortByPrice: sortBy.value.value,
        perPage: filters.value.perPage,
        priceRange: filters.value.priceRange,
        categorization: filters.value.categories,
        properties: (filters.value.brands) ? {
          brand: filters.value.brands,
        } : null,
      })
        .then(response => {
          products.value = response.data.products
          totalProducts.value = response.data.products.total
        })
    }

    fetchShopProducts()

    watch(() => filters.value.page, () => {
      fetchShopProducts()
    }, { deep: true })
    /* watch([filters, sortBy], () => {
      console.log(filters)
      fetchShopProducts()
    }, {
      deep: true,
    }) */
    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,
      fetchShopProducts,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    cleanFilters(filters) {
      this.filters = filters
    },
    filterAction() {
      this.fetchShopProducts()
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.m-w-img{
  max-width: 200px!important;
}
</style>
